import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';
import { environment } from 'src/environments/environment';
import { UniversityService } from 'src/app/core/services/university/university.service';

@Injectable()
export class UniversityInterceptor implements HttpInterceptor {
    isAllApplication: boolean = false;
    constructor(
        private _localstorageService: LocalStorageService,
        private _universityService: UniversityService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const university: any = this._localstorageService.getUniversity();
        this._universityService.getIsAllApplication.subscribe(
            (status: boolean) => {
                this.isAllApplication = status;
            }
        );
        const tenant: any = environment.tenant;

        // Bypass the interceptor for asset requests (like i18n or images)
        if (request.url.includes('/assets/')) {
            return next.handle(request);
        }

        let url = request.url;

        // Ensure tenant is added if it doesn't already exist
        if (!url.includes('tenant=') && !url.includes('raiseDispute')) {
            url = url.includes('?')
                ? `${url}&tenant=${tenant}`
                : `${url}?tenant=${tenant}`;
        }

        // Only append universityId if a valid university is present
        if (university && university.id && !url.includes('universityId=')) {
            url = url.includes('?')
                ? !this.isAllApplication &&
                  `${url}&universityId=${university.id}`
                : !this.isAllApplication &&
                  `${url}?universityId=${university.id}`;
        }

        // Clone the request with the updated URL
        const clonedRequest = request.clone({
            url: url,
        });

        return next.handle(clonedRequest);
    }
}
