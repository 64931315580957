export const UniversityAPI = {
    getUrmUniversity: 'university/urm',
    getUniversityWebsite: 'university/website',
    getUniversity: 'university/list',
    getUniversityProcess: 'universityProcessLink',
    universityAdmin: 'university/admin?universityId',
    universityContract: 'universityContract',
    getBoardName: 'boardName',
    getUniversityWithEligibility: 'university/website/checkEligibility',
    getUniversityDetails: 'university/getById',
    getUniversityAffiliate: 'universityAffiliate/getById/documentList',
    optionsGetById: 'options/getById',
    getUniversityByTenant: 'university/getuniversityall',
    getUniversityForApplyCourse: 'courses/website/uneeqFilter',
    
};
